import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { ROUTES } from "../utils/constants";
import { useTranslation } from 'react-i18next';
import Index from "../pages/Index";
import Header from "../components/Header";
import { useState } from "react";


const AppRouter = () => {
    const { t } = useTranslation();



    return <BrowserRouter>
        <Header />

        <Routes>
            <Route exact path={ROUTES.INDEX} element={<Index />} />
            {/* <Route exact path={ROUTES.NOT_FOUND} element={<OtherPages text1={t("notFound1")} text2={t("notFound2")} />} /> */}
            {/* <Route exact path={ROUTES.LINK_NOT_FOUND} element={<OtherPages text1={t("linkNotFound")} />} /> */}
            {/* <Route exact path={ROUTES.LINK_NOT_FOUND} element={<SurveyPage />} /> */}

            <Route
                path="*"
                element={<Navigate to="/" replace />}
            />
        </Routes>

    </BrowserRouter>
}

export default AppRouter
