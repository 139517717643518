//disable yellow errors for this page
/* eslint-disable import/no-anonymous-default-export */

export default {

    tr: {
        translation: {
            languageButton: "TR",
            language: "Tr",

            notFound1: "404",
            notFound2: "Aradığınız sayfaya ulaşılamadı.",

            linkNotFound: 'Lütfen size verilen link üzerinden giriş yapınız.',

            send: "GÖNDER",
            thanks: "Görüşlerinizi paylaştığınız için teşekkür eder, iyi günler dileriz.",

            alreadyCompleted: "Bu anketi daha önce tamamladınız.",
            yes: "EVET",
            no: "HAYIR",
            expandButton: "Genişlet",

            shrinkButton: "Daralt",
            download: "İNDİR",
            view: "Görüntüle",
            wMCertificate: "Garanti ve Bakım Belgesi",
            serviceAcceptanceForm: "Genel Onarım Şartları",


            infoHeader: "Renault modellerinin broşürlerini indir",
            infoHeader2: "Modelin üzerine tıklayarak broşürü indirebilirsin.",
            vehicleCar: "Binek Araçlar",
            commercialCar: "Ticari Araçlar",

        }
    },
    en: {
        translation: {
            language: "EN",
            languageButton: "EN",
            shrinkButton: "SHRINK",
            expandButton: "EXPAND",

            notFound1: "404",
            notFound2: "Page Not Found.",

            linkNotFound: 'Please login using the link provided to you.',

            send: "SEND",
            thanks: "Thank you for your feedback, good articles.",

            alreadyCompleted: "You have already completed this survey.",
            yes: "YES",
            no: "NO",

            download: "Download",
            view: "View",
            wMCertificate: "Warranty and Maintenance Certificate",
            serviceAcceptanceForm: "General Terms of Repair",

            infoHeader: "EN Renault modellerinin broşürlerini indir",
            infoHeader2: "ENModelin üzerine tıklayarak broşürü indirebilirsin. ",
            vehicleCar: "EN Binek Araçlar",
            commercialCar: "EN Ticari Araçlar",
        }
    }
}
