import { React, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { IMAGES, isRenault } from '../utils/constants';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography, useMediaQuery } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DEFAULT_THEME, getTheme } from '../utils/theme';
import CustomAccordion from './CustomAccordion';


const CarGroups = (props) => {
    const { t } = useTranslation()
    const isTablet = useMediaQuery(getTheme().breakpoints.down('tablet'));

    const styles = {

    }


    const groups = [
        {
            type: t("vehicleCar"),
            cars: [
                { name: t("Clio"), value: ("https://rshub.blob.core.windows.net/qr-brosur/clio-brosur.pdf"), type: "PDF ", size: "4.5" },
                { name: t("Zoe E-Tech"), value: ("https://rshub.blob.core.windows.net/qr-brosur/zoe-etech-brosur.pdf"), type: "PDF ", size: "0.9" },
                { name: t("Captur"), value: ("https://rshub.blob.core.windows.net/qr-brosur/captur-brosure.pdf"), type: "PDF ", size: "8.2" },
                { name: t("Megane Sedan"), value: ("https://rshub.blob.core.windows.net/qr-brosur/megane-sedan-brosur.pdf"), type: "PDF ", size: "5.6" },
                { name: t("Taliant"), value: ("https://rshub.blob.core.windows.net/qr-brosur/taliant-brosure.pdf"), type: "PDF ", size: "8.1" },
                { name: t("Koleos"), value: ("https://rshub.blob.core.windows.net/qr-brosur/koleos-brosur.pdf"), type: "PDF ", size: "4.2" },
                { name: t("Austral"), value: ("https://rshub.blob.core.windows.net/qr-brosur/austral-brosur.pdf"), type: "PDF ", size: "5.6" },
                { name: t("Megane E-Tech"), value: ("https://rshub.blob.core.windows.net/qr-brosur/megane-etech-brosur.pdf"), type: "PDF ", size: "12.7" },
            ]
        },
        {
            type: t("commercialCar"),
            cars: [
                { name: t("Express Combi"), value: ("https://rshub.blob.core.windows.net/qr-brosur/Yeni-Express-Combi-brosur.pdf"), type: "PDF ", size: "3" },
                { name: t("Trafic Combi"), value: ("https://rshub.blob.core.windows.net/qr-brosur/trafic-combi.pdf"), type: "PDF ", size: "2.3" },
                { name: t("Express Van"), value: ("https://rshub.blob.core.windows.net/qr-brosur/Yeni-Express-Van-brosur.pdf"), type: "PDF ", size: "4.1" },
                { name: t("Trafic Van"), value: ("https://rshub.blob.core.windows.net/qr-brosur/Yeni-Trafic-Panelvan-Brosur.pdf"), type: "PDF ", size: "3.2" },
                { name: t("Master"), value: ("https://rshub.blob.core.windows.net/qr-brosur/yeni-master-brosur.pdf"), type: "PDF ", size: "8.5" },
            ]
        },

    ]

    return (

        <Grid container spacing={12}>
            {groups.map((f, i) => {
                return (
                    <Grid item xs={12} sm={6}>
                        <CustomAccordion carType={f.type} cars={f.cars} i={i} />
                    </Grid >
                )
            })}
        </Grid >
    )
}

export default CarGroups;