

const DEFAULT_LANGUAGE = 'tr';

const ROUTES = {
    INDEX: '/',
    NOT_FOUND: '/404',
    LINK_NOT_FOUND: '/',
}

const IMAGES = {
    LOGO_MAIN: 'renault_logo_white.png',
    FAVICON: 'favicon.png',
    TITLE: 'RENAULT KÜTÜPHANE',
    VEHICLE_PAGE_IMG: 'renault_vehicle_page_image.jpg',
    SERVICE_PAGE_IMG: 'renault_service_page_image.jpg',
    COMMENT_PAGE_IMG: 'renault_comment_page_image.jpg',
    ERROR_PAGE_IMG: 'error_renault.jpg',
}

export {
    IMAGES,
    DEFAULT_LANGUAGE,
    ROUTES,
}