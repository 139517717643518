// React

// Libraries

// Utils

// MUI
import Button from '@mui/material/Button';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useTranslation } from 'react-i18next';
import { DEFAULT_THEME } from '../utils/theme';
import { Grid, Typography } from '@mui/material';
import axios from 'axios';
import saveAs from 'file-saver';

// Custom Components

// Redux

// Services

export default function DownloadButton(props) {

    const { style, file, content } = props
    const { t } = useTranslation()

    // For Download
    async function downloadSingleFile(url, fileName) {
        try {
            const data = await axios.get(url, { responseType: 'blob' })
            saveAs(data.data, fileName);
            return Promise.resolve()
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const styles = {
        button: {
            background: "#FFFFFF",
            border: "1.5px solid #000000",
            borderRadius: "3px",
            width: '334px',
            height: '59px',
            fontFamily: DEFAULT_THEME.typography.fontFamilyBold,
            color: "#000000",
            '&:hover': {
                background: "#FFFFFF",
                border: "1px solid #000000",
            },

        },
        buttonInfoTitle: {
            pl: "10px",
            // mt: "10px",
            fontSize: "18px",
            fontFamily: DEFAULT_THEME.typography.fontFamilyBold,

        },
        buttonInfoSubtitle: {
            pl: "10px",
            fontSize: "10px",
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            alignSelf: "start",
        },
        iconInfo: {
            pr: "6px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

        },
    }

    return (
        <Button sx={{ ...styles.button }} onClick={() => downloadSingleFile(content.value, content.name)} {...props}>
            <Grid container justifyContent="space-between">
                <Grid item xs={10} container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start">

                    <Grid item >
                        <Typography sx={{ ...styles.buttonInfoTitle }}>{content.name}</Typography>
                    </Grid>
                    <Grid item >
                        <Typography sx={{ ...styles.buttonInfoSubtitle }}>
                            {content.type} Belgesi - {content.size} Mb</Typography>
                    </Grid>

                </Grid>
                <Grid item xs={2} sx={{ ...styles.iconInfo }}>
                    <FileDownloadOutlinedIcon />
                </Grid>
            </Grid>
        </Button>
    );
}
