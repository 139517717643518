import React from "react";

//MUI
import { Grid, useMediaQuery } from "@mui/material";
import { IMAGES } from "../utils/constants";
import { getTheme } from "../utils/theme";
import { useTranslation } from 'react-i18next'


const Header = (props) => {
    const isDesktop = useMediaQuery(getTheme().breakpoints.down('desktop'));
    const isTablet = useMediaQuery(getTheme().breakpoints.down('tablet'));
    const { t } = useTranslation();


    const styles = {
        root: {

            minHeight: "10vh",
            backgroundColor: "#000000",
        },
        logo: {
            pl: isTablet ? "5vw" : "3vw",
            margin: "3px",
            textAlign: 'center',
        },

    }

    return (
        <Grid
            container
            // direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ ...styles.root }}
        >

            {/* LOGO */}
            <Grid item

                sx={{ ...styles.logo }}
            >
                <img
                    alt={'null'}
                    src={IMAGES.LOGO_MAIN}
                    height={isDesktop ? isTablet ? "30vh" : "40vh" : "50vh"}

                />
            </Grid>
            {/* <Grid item >
                <LanguageButton >
                    {t('languageButton')}
                </LanguageButton>
            </Grid> */}
        </Grid>
    )
}

export default Header;