import { React, useState } from 'react';
import { Button, Grid, Typography, useMediaQuery } from '@mui/material';
import DownloadButton from './DownloadButton';
import { DEFAULT_THEME, getTheme } from '../utils/theme';


const CustomAccordion = (props) => {
    const { carType, cars = "", i } = props
    const isSm = useMediaQuery(getTheme().breakpoints.down('md'));


    const styles = {
        primaryButton: {
            width: '334px',
            height: '100px',
            background: "#000000",
            fontFamily: DEFAULT_THEME.typography.fontFamilyBold,
            color: "#ffffff",
            fontSize: "40px",
            mb: "1rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },


    }

    return (
        <Grid container justifyContent={isSm ? "center" : i % 2 === 0 ? "flex-end" : "flex-start"}>
            {/* BINEK TICARI */}
            < Grid item >
                <Typography sx={{ ...styles.primaryButton }} >
                    {carType}
                </Typography>

                {/* SECENEKLER */}
                <Grid item xs={12}
                    justifyContent="center"
                >
                    {cars.map((c, i) => {
                        return (
                            <Grid >
                                <DownloadButton content={c} ></DownloadButton>
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid >
        </Grid >
    );
}

export default CustomAccordion;
