import { React, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { IMAGES, isRenault } from '../utils/constants';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography, useMediaQuery } from '@mui/material';
import CarGroups from '../components/CarGroups';
import { DEFAULT_THEME, getTheme } from '../utils/theme';


const Index = (props) => {
    const { t } = useTranslation()
    const isTablet = useMediaQuery(getTheme().breakpoints.down('tablet'));

    const styles = {
        root: {
            padding: "0rem 1rem 8rem 1rem  ",

            backgroundImage: `url(${'R-DAM_1395901.png'})`,
            backgroundSize: 'cover',
            backgroundAttachment: "scroll",
            backgroundPosition: "center",
        },
        background: {
        },
        accordion: {
            margin: "4rem 0rem 1rem 0rem  "

        },
        info: {
            mt: "2rem",

        },
        infoHeader: {
            textAlign: "center",
            fontFamily: DEFAULT_THEME.typography.fontFamilyBold,
            fontWeight: "700",
            fontSize: "48px",
            lineHeight: "58px",
        },
        infoHeader2: {
            textAlign: "center",
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: "300",
            fontSize: "20px",
            lineHeight: "24px",
            color: "rgba(0, 0, 0, 0.5)"
        },
    };

    return (
        <Grid container justifyContent={"center"} sx={{ ...styles.root }}>
            {/* Hader Info */}
            <Grid item xs={12} container sx={{ ...styles.info }}>
                <Grid item xs={12}>
                    <Typography sx={{ ...styles.infoHeader }}>{t("infoHeader")}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography sx={{ ...styles.infoHeader2 }}>{t("infoHeader2")}</Typography>
                </Grid>
            </Grid>

            {/* Accordion */}
            <Grid item xs={12} sx={{ ...styles.accordion }}>
                <CarGroups />
            </Grid>
        </Grid >
    )
}

export default Index;